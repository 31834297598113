import React from 'react';
import styles from './morepage.module.css';
import { moreNavigationMenu } from '../Navigation/MoreNavigation';
import { useNavigate } from 'react-router-dom';
import PremiumModal from '../PremiumModal/PremiumModal';

export const MorePage = () => {
  const navigate = useNavigate();

  const handleNavigation = (item) => {
    if (item.title === "Premium") {
      handleOpenPremiumModal();
    } else if (item.title === "Docs") {
      window.open(item.url, '_blank');
    } else {
      navigate(item.path);
    }
  };

  const [openPremiumModal, setOpenPremiumModal] = React.useState(false);
  const handleOpenPremiumModal = () => setOpenPremiumModal(true);
  const handleClosePremiumModal = () => setOpenPremiumModal(false);

  return (
    <div className={styles.morePageContainer}>
      <div className={styles.menuGrid}>
        {moreNavigationMenu.map((item) => (
          <div
            className={styles.menuItem}
            onClick={() => handleNavigation(item)}
            key={item.title}
          >
            {item.outlinedIcon}
            <p className={styles.menuText}>{item.title}</p>
          </div>
        ))}
      </div>

      <section>
        <PremiumModal open={openPremiumModal} handleClose={handleClosePremiumModal} />
      </section>
    </div>
  );
};