import React, { useEffect, useState } from 'react'
import styles from './playerrankingpage.module.css'
import { FixedHeader } from '../../../Utils/Components/FixedHeader/FixedHeader'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Tab, Tabs } from '../../../Utils/Components/Tabs/Tabs';
import { api, ensureAuthorizationHeader } from '../../../Config/api';
import { RenderPremiumBadge } from '../../Utils/RenderPremiumBadge/RenderPremiumBadge';
import { useNavigate } from 'react-router-dom';

export const PlayerRankingPage = () => {

  const navigate = useNavigate();

  const [rankingData, setRankingData] = useState({
    level: [],
    followers: [],
    streak: [],
  });

  useEffect(() => {
    const fetchRankingData = async () => {

      try {
        ensureAuthorizationHeader();
        const { data } = await api.get(
          "/api/player/ranking"
        );
        setRankingData((prevRankingData) => ({
          ...prevRankingData,
          followers: data.followersRanking,
          level: data.levelRanking,
          streak: data.streakRanking,
        }));

      } catch (error) {
        console.error("Error fetching ranking data:", error);
      } finally {

      }
    };

    fetchRankingData();
  }, []);

  return (
    <div className={styles.container}>
      <FixedHeader
        title="Ranking"
        cotitle={'(players)'}
        rightContent={
          <EmojiEventsIcon />
        } />

      <section className={styles.tabContainer}>
        <Tabs>
          <Tab label="Level">
            <div className={styles.cofoundersList}>
              {rankingData?.level?.map((user) => {
                return (
                  <div className={styles.cofounderItem} key={user?.username} onClick={() => navigate(`/profile/user/${user?.username}`)}>
                    <div className={styles.cofounderInfo}>
                      <img
                        src={user?.profileImageUrl}
                        alt="Co-founder avatar"
                        className={styles.cofounderAvatar}
                      />
                      <div className={styles.cofounderDetails}>
                        <div className={styles.upperSection}>
                          <div className={styles.nameAndBadge}>
                            <p className={styles.name}>{user?.name}</p>
                            <RenderPremiumBadge activeSubscriptionType={user?.activeSubscriptionType} />
                          </div>
                          <p className={styles.level}>Lvl. {user?.level}</p>
                        </div>

                        <p className={styles.username}>{user?.username}</p>
                      </div>
                    </div>
                    <div className={styles.aboutContainer}>
                      <p className={styles.description}>{user?.about}</p>
                    </div>

                  </div>)
              })}
            </div>
          </Tab>
          <Tab label="University">
            <div>¡Próximamente!</div>
          </Tab>
          <Tab label="Followers">
            <div className={styles.cofoundersList}>
              {rankingData?.followers?.map((user) => {
                return (
                  <div className={styles.cofounderItem} key={user?.username} onClick={() => navigate(`/profile/user/${user?.username}`)}>
                    <div className={styles.cofounderInfo}>
                      <img
                        src={user?.profileImageUrl}
                        alt="Co-founder avatar"
                        className={styles.cofounderAvatar}
                      />
                      <div className={styles.cofounderDetails}>
                        <div className={styles.upperSection}>
                          <div className={styles.nameAndBadge}>
                            <p className={styles.name}>{user?.name}</p>
                            <RenderPremiumBadge activeSubscriptionType={user?.activeSubscriptionType} />
                          </div>
                          <p className={styles.level}>Followers: {user?.followersNumber}</p>
                        </div>

                        <p className={styles.username}>{user?.username}</p>
                      </div>
                    </div>
                    <div className={styles.aboutContainer}>
                      <p className={styles.description}>{user?.about}</p>
                    </div>

                  </div>)
              })}
            </div>
          </Tab>
          <Tab label="Streak">
            <div className={styles.cofoundersList}>
              {rankingData?.streak?.map((user) => {
                return (
                  <div className={styles.cofounderItem} key={user?.username} onClick={() => navigate(`/profile/user/${user?.username}`)}>
                    <div className={styles.cofounderInfo}>
                      <img
                        src={user?.profileImageUrl}
                        alt="Co-founder avatar"
                        className={styles.cofounderAvatar}
                      />
                      <div className={styles.cofounderDetails}>
                        <div className={styles.upperSection}>
                          <div className={styles.nameAndBadge}>
                            <p className={styles.name}>{user?.name}</p>
                            <RenderPremiumBadge activeSubscriptionType={user?.activeSubscriptionType} />
                          </div>
                          <p className={styles.level}>Streak: {user?.streak}</p>
                        </div>

                        <p className={styles.username}>{user?.username}</p>
                      </div>
                    </div>
                    <div className={styles.aboutContainer}>
                      <p className={styles.description}>{user?.about}</p>
                    </div>

                  </div>)
              })}
            </div>
          </Tab>
        </Tabs>
      </section>

    </div>
  )
}
