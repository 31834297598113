import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import GreenPremiumBadge from '../static/PremiumBadge.png';
import GoldenPremiumBadge from '../static/GoldenPremiumBadge.png';
import ImageIcon from '@mui/icons-material/Image'
import PollIcon from '@mui/icons-material/Poll';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { createPost } from '../../../Store/Post/Action';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CloseVideoImageIcon from '../../Assets/CloseIcon.svg'

import CloseIcon from '@mui/icons-material/Close';

import styles from './postreplymodal.module.css'
import { RenderPremiumBadge } from '../../Utils/RenderPremiumBadge/RenderPremiumBadge';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -100%)',
    width: 600,
    bgcolor: '#000000',
    border: 'none',
    boxShadow: 24,
    p: 4,
    outline: "none",
    borderRadius: 4
};

export default function PostReplyModal({ handleClose, open, repliedPost, displayTime, handleOpenLimitPostsModal, isFeed }) {
    const navigate = useNavigate();
    const [uploadingImage, setUploadingImage] = React.useState(false);
    const [selectImage, setSelectedImage] = React.useState("");
    const [charCount, setCharCount] = React.useState(0);
    const dispatch = useDispatch();

    // Mantienen el estado de si se ve el input para poner la URL de imagen y de vídeo
    const [showImageInput, setShowImageInput] = React.useState(false);
    const [showVideoInput, setShowVideoInput] = React.useState(false);

    const handleAddMedia = (type, url) => {
        if ((formik.values.image.length + formik.values.video.length) < 2) {
            formik.setFieldValue(type, [...formik.values[type], url]);
            formik.setFieldValue(`${type}Url`, '');
        }

        if (type === 'video') {
            setShowVideoInput(false)
        } else {
            setShowImageInput(false)
        }
    };

    const handleRemoveMedia = (index, type) => {
        console.log(type)
        const updatedMedia = [...formik.values[type]];
        updatedMedia.splice(index, 1);
        formik.setFieldValue(type, updatedMedia);
    };
    

    const renderMediaPreviews = () => {
        const allMedia = [
            ...formik.values.image.map(url => ({ url, type: 'image' })),
            ...formik.values.video.map(url => ({ url, type: 'video' }))
        ];

        return allMedia.map((media, index) => (
            <div key={index} className={styles.mediaPreview}>
                <div className={styles.mediaWrapper}>
                    {media.url.includes('youtube.com') ? (
                        <div className={styles.videoWrapper}>
                            <iframe
                                width="400"
                                height="225"
                                src={`https://www.youtube.com/embed/${getYouTubeVideoId(media.url)}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                                className={styles.responsiveIframe}
                            ></iframe>
                        </div>
                    ) : (
                        <img src={media.url} alt={`Media ${index + 1}`} className={styles.responsiveImage} />
                    )}
                    <button
                        className={styles.removeMediaButton}
                        onClick={() => handleRemoveMedia(index, media.type)}
                        type="button"
                    >
                        <img src={CloseVideoImageIcon} className={styles.closeIcon} />
                    </button>
                </div>
            </div>
        ));
    };

    // Función auxiliar para extraer el ID del video de YouTube de la URL
    const getYouTubeVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    const handleSubmit = (values, actions) => {
        dispatch(createPost(values, handleOpenLimitPostsModal))
        setCharCount(0);
        handleClose()
        actions.resetForm();
        setSelectedImage('');
    }

    const handleCloseWithNavigation = () => {
        if (isFeed) {
            navigate(-1);
        }
        handleClose();
    }


    const formik = useFormik({
        initialValues: {
            content: "",
            isPost: repliedPost ? false : true,
            isReply: repliedPost ? true : false,
            replyForId: repliedPost && repliedPost?.id,
            image: [],
            video: [],
        },
        onSubmit: handleSubmit
    })

    const handleSelectImage = (event) => {
        setUploadingImage(true);
        const imgUrl = event.target.files[0]
        formik.setFieldValue("image", imgUrl);
        setSelectedImage(imgUrl);
        setUploadingImage(false);
    }

    const handleInputChange = React.useCallback((e) => {
        const value = e.target.value;
        setCharCount(value.length);
        formik.handleChange(e);
    }, [formik]);

    const autoResize = React.useCallback((e) => {
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }, []);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleCloseWithNavigation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                slotProps={{
                    backdrop: { style: { backgroundColor: 'rgba(105, 161, 136, 0.2)' } },
                    root: { style: { overflow: 'auto' } }
                }}
            >
                <div className={styles.modalContent}>

                    <div className={styles.contentWrapper}>

                        <form onSubmit={formik.handleSubmit}>

                            <div className={styles.header}>
                                {/* <img src={WhiteBackArrow} /> */}
                                <button onClick={handleCloseWithNavigation}>
                                    <CloseIcon className={styles.closeIcon} />
                                </button>
                                <button className={styles.mobilePostReplyButton} type="submit"><strong>{repliedPost ? <p>Reply</p> : <p>Post</p>}</strong></button>
                            </div>

                            {/* Contenedor con el post que se va a responder. Si se trata de un post normal y no de una respuesta, no se renderiza.*/}
                            {repliedPost &&
                                <div className={styles.replyTargetContainer}>
                                    <Avatar
                                        onClick={() => navigate(`/profile/${6}`)}
                                        className='cursor-pointer'
                                        alt='username'
                                        src={repliedPost?.playerTarget?.profileImageUrl}
                                    />
                                    <div>
                                        <div>
                                            <div>

                                                <span><strong>{repliedPost?.playerTarget?.name}</strong></span>
                                                <span className='text-gray-500'>{repliedPost?.playerTarget?.username} . {displayTime}</span>
                                                <RenderPremiumBadge activeSubscriptionType={repliedPost?.playerTarget?.premiumSubscription?.activeSubscriptionType}/>

                                            </div>

                                        </div>

                                        <div onClick={() => navigate(`/publication/${3}`)} className={styles.marginTop2}>

                                            <p>{repliedPost?.content}</p>

                                        </div>
                                    </div>

                                </div>
                            }

                            <section className={styles.inputContainer}>

                                <Avatar alt="username" src='http://res.cloudinary.com/dnbw04gbs/image/upload/v1690639851/instagram%20post/bywtgh9vj4e80aywstss.png' />
                                <div className={styles.formContainer}>

                                    <div className={styles.textareaContainer}>
                                        <textarea
                                            type="text"
                                            name='content'
                                            placeholder='Anything to add?'
                                            className={styles.inputField}
                                            {...formik.getFieldProps("content")}
                                            onInput={(e) => {
                                                autoResize(e);
                                                handleInputChange(e);
                                            }}
                                            maxLength={280}
                                            rows={1}
                                        />
                                        {formik.errors.content && formik.touched.content && (
                                            <span>{formik.errors.content}</span>
                                        )}

                                    </div>

                                    <div className={styles.imageVideoContainer}>
                                        {showImageInput && (
                                            <div className={styles.imageVideoInputContainer}>
                                                <input
                                                    type="text"
                                                    name="imageUrl"
                                                    placeholder="Imgur image URL..."
                                                    className={styles.imageVideoInputField}
                                                    {...formik.getFieldProps('imageUrl')}
                                                />
                                                <button
                                                    className={styles.imageVideoInputButton}
                                                    type="button"
                                                    onClick={() => handleAddMedia('image', formik.values.imageUrl)}
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        )}
                                        {showVideoInput && (
                                            <div className={styles.imageVideoInputContainer}>
                                                <input
                                                    type="text"
                                                    name="videoUrl"
                                                    placeholder="YouTube video URL..."
                                                    className={styles.imageVideoInputField}
                                                    {...formik.getFieldProps('videoUrl')}
                                                />
                                                <button className={styles.imageVideoInputButton} type="button" onClick={() => handleAddMedia('video', formik.values.videoUrl)}>Add</button>
                                            </div>
                                        )}
                                        {(formik.errors.image || formik.errors.video) && (
                                            <span className={styles.errorText}>{formik.errors.image || formik.errors.video}</span>
                                        )}
                                    </div>

                                </div>

                            </section>

                            <div className={styles.mediaContent}>
                                {renderMediaPreviews()}
                            </div>

                            <div className={styles.footerContainer}>
                                <div className={styles.buttonsContainer}>
                                    <ImageIcon
                                        className={styles.iconText}
                                        onClick={() => setShowImageInput(!showImageInput)} />
                                    <YouTubeIcon
                                        className={styles.iconText}
                                        onClick={() => setShowVideoInput(!showVideoInput)} />
                                    <PollIcon className={styles.iconText} />
                                    <TagFacesIcon className={styles.iconText} />
                                </div>

                                <button className={styles.postReplyButton} type="submit"><strong>{repliedPost ? <p>Reply</p> : <p>Post</p>}</strong></button>

                            </div>

                        </form>

                    </div>
                </div>
            </Modal>
        </div>
    );
}