import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import GroupsIcon from '@mui/icons-material/Groups';
import ArticleIcon from '@mui/icons-material/Article';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import HowToVoteIcon from '@mui/icons-material/HowToVote';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Person2Icon from '@mui/icons-material/Person2';


import styles from './legislaturepage.module.css';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { calculateInitialPosition } from '../WorldviewPage/InitialPosition';
import { Newspaper } from '@mui/icons-material';
import { api, ensureAuthorizationHeader } from '../../Config/api';


export const LegislaturePage = () => {

    // Obtén los parámetros de búsqueda
    const [searchParams] = useSearchParams();

    // Obtiene el valor del parámetro 'lowerHouse'
    const isLowerHouse = searchParams.get("lowerHouse");

    // Username del State
    const { username } = useParams();

    const [loading, setLoading] = useState(true);

    const [houseData, setHouseData] = useState();


    useEffect(() => {
        const fetchHouseData = async () => {
            setLoading(true);
            try {
                ensureAuthorizationHeader();
                const { data } = await api.get(
                    `/api/org/house?stateUsername=${username}&isLowerHouse=${isLowerHouse}`
                );
                setHouseData(data); // Maneja la respuesta de la API aquí

            } catch (error) {
                console.error("Error fetching house data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchHouseData();
    }, [username, isLowerHouse]);

    // Convert arrays to objects for faster access
    const parliamentaryGroups = useMemo(() => {
        return houseData?.parliamentaryGroups.reduce((acc, group) => {
            acc[group?.parliamentaryGroup?.id] = group?.parliamentaryGroup;
            return acc;
        }, {});
    }, [houseData?.parliamentaryGroups]);


    const [modalIsOpen, setModalIsOpen] = useState(false);

    const initialPosition = calculateInitialPosition();

    const [cursorStyle, setCursorStyle] = useState('auto');

    const handleZoom = (e) => {
        const value = e.state.scale;
        setCursorStyle(value > 1 ? 'grab' : 'auto');
    }

    const svgContainerRef = useRef(null);

    const [selectedLegislator, setSelectedLegislator] = useState(null);

    const resetOpacities = () => {
        const svgContainer = svgContainerRef.current;
        const circles = svgContainer.querySelectorAll('circle');
        const images = svgContainer.querySelectorAll('image');

        // Restaurar opacidad a todos los círculos e imágenes
        circles.forEach((circle) => (circle.style.opacity = '1'));
        images.forEach((img) => (img.style.opacity = '1'));

        // Quitar la selección actual
        setSelectedLegislator(null);
    };

    const handleSvgClick = (event) => {
        const target = event.target;
        const svgContainer = svgContainerRef.current;
        let circle = null;
        let imageHref = null;

        // Si se hace clic en un círculo
        if (target.tagName === 'circle') {
            circle = target;

            // Buscar la imagen relacionada al círculo
            const defs = circle.nextElementSibling; // El siguiente hermano del círculo debería ser el <defs>
            if (defs?.nextElementSibling?.tagName === 'g') {
                const group = defs.nextElementSibling; // El grupo que contiene la imagen
                const image = group.querySelector('image');
                if (image) {
                    imageHref = image.getAttribute('href'); // Obtener la URL de la imagen
                }
            }
        }
        // Si se hace clic en una imagen
        else if (target.tagName === 'image') {
            const group = target.closest('g'); // Encontrar el grupo que contiene la imagen
            const defs = group.previousElementSibling; // El defs relacionado
            circle = defs?.previousElementSibling; // El círculo relacionado
            imageHref = target.getAttribute('href'); // Obtener el href directamente del target
        }

        if (circle) {
            const legislatorData = {
                username: circle.getAttribute('data-username'),
                name: circle.getAttribute('data-name'),
                groupId: circle.getAttribute('data-group-id'),
                imageHref: imageHref, // La URL de la imagen asociada
            };

            setSelectedLegislator(legislatorData);

            // Actualizar opacidades de todos los círculos e imágenes, excepto el seleccionado y su correspondiente
            const circles = svgContainer.querySelectorAll('circle');
            const images = svgContainer.querySelectorAll('image');

            circles.forEach((c) => {
                c.style.opacity = c.getAttribute('data-username') !== legislatorData.username ? '0.5' : '1';
            });

            images.forEach((img) => {
                const group = img.closest('g');
                const relatedCircle = group.previousElementSibling?.previousElementSibling; // El círculo está antes del defs
                img.style.opacity = relatedCircle?.getAttribute('data-username') !== legislatorData.username ? '0.5' : '1';
            });
        } else {
            // Si se hace clic fuera de cualquier círculo o imagen
            if (selectedLegislator) {
                resetOpacities();
            }
        }
    };

    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <div>
                <div className={styles.sidebar}>

                    <div className={styles.regionContainer}>

                        <div className={`${styles.region} ${styles.currentRegion}`} onClick={() => {
                            console.log(houseData?.houseSVG)
                            navigate(`/profile/state/${username}`)
                        }
                        }>
                            <img className={styles.shield} src={'https://upload.wikimedia.org/wikipedia/commons/7/7d/Escudo_de_Espa%C3%B1a_%28variant%29.svg'} />
                            <div className={styles.regionDetails}>
                                <p className={styles.regionTitle}><strong>{houseData?.stateStructure?.name}</strong></p>
                                <p className={styles.regionSubtitle}>{username}</p>
                            </div>
                        </div>

                        <div className={`${styles.region} ${styles.currentRegion}`} onClick={() => navigate(`/profile/region/prueba`)}>
                            <img className={styles.shield} src={'https://pbs.twimg.com/profile_images/1852848209544273920/n6ME-Z2t_400x400.jpg'} />
                            <div className={styles.regionDetails}>
                                <p className={styles.regionTitle}><strong>Congreso de los Diputados</strong></p>
                                <p className={styles.regionSubtitle}>Lower House</p>
                            </div>
                        </div>

                        <div className={`${styles.region} ${styles.currentRegion}`} onClick={() => navigate(`/profile/user/${selectedLegislator?.username}`)}>
                            <img className={styles.legislator} src={selectedLegislator?.imageHref} />
                            <div className={styles.regionDetails}>
                                <p className={styles.regionTitle}><strong>{selectedLegislator?.name}</strong></p>
                                <p className={styles.regionSubtitle}>{selectedLegislator?.username}</p>
                            </div>
                        </div>

                        {parliamentaryGroups && selectedLegislator &&
                            <div className={`${styles.region} ${styles.currentRegion}`} onClick={() => { console.log(parliamentaryGroups); }}>
                                <img className={styles.shield} src={parliamentaryGroups[selectedLegislator?.groupId]?.profileImageUrl} />
                                <div className={styles.regionDetails}>

                                    <p className={styles.regionTitle}><strong>{parliamentaryGroups[selectedLegislator?.groupId]?.name}</strong></p>

                                    <p className={styles.regionSubtitle}>Parliamentary group</p>
                                </div>
                            </div>
                        }

                    </div>


                    <div className={styles.mapModeContainer}>
                        <div className={styles.menuToogle} onClick={() => setModalIsOpen(!modalIsOpen)}>
                            <span className={styles.line} />
                            <span className={styles.line} />
                            <span className={styles.line} />
                        </div>
                        <div
                            className={`${modalIsOpen ? `${styles.show} ${styles.menu} ${styles.open}` : `${styles.show} ${styles.menu} ${styles.hidden}`}`}
                        >
                            <p className={styles.houseMenu}>House menu</p>
                            <div className={styles.closeButton} onClick={() => setModalIsOpen(false)}>
                                <span className={styles.lineX} />
                                <span className={styles.lineX} />
                            </div>

                            <div className={styles.buttonsContainer}>
                                <button>
                                    <div>
                                        <CalendarMonthIcon />
                                        Agenda
                                    </div>
                                    <ChevronRightIcon />
                                </button>
                                <button>
                                    <div>
                                        <GroupsIcon />
                                        Groups
                                    </div>
                                    <ChevronRightIcon />
                                </button>
                                <button>
                                    <div>
                                        <Diversity3Icon />
                                        Committees
                                    </div>
                                    <ChevronRightIcon />
                                </button>
                                <button>
                                    <div>
                                        <HowToVoteIcon />
                                        Elections
                                    </div>
                                    <ChevronRightIcon />
                                </button>
                                <button>
                                    <div>
                                        <Newspaper />
                                        Press office
                                    </div>
                                    <ChevronRightIcon />
                                </button>
                                <button>
                                    <div>
                                        <Person2Icon />
                                        Presidency
                                    </div>
                                    <ChevronRightIcon />
                                </button>
                                <button>
                                    <div>
                                        <LocationOnIcon />
                                        Location
                                    </div>
                                    <ChevronRightIcon />
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className={styles.mapContainer}>

                    <TransformWrapper
                        initialScale={1.0}
                        initialPositionX={0}
                        initialPositionY={0}
                        minScale={1}
                        maxScale={30}
                        disabled={false}
                        limitToBounds={true}
                        onZoom={(value) => handleZoom(value)}
                        wheel={{ smoothStep: 0.01 }}
                    >
                        <TransformComponent>
                            <div
                                className={styles.mapContainer}

                            >
                                <svg
                                    dangerouslySetInnerHTML={{ __html: houseData?.houseSVG }}
                                    preserveAspectRatio="xMidYMid meet"
                                    onClick={handleSvgClick}
                                    width="100vw"
                                    height="100%"
                                    ref={svgContainerRef}
                                />
                            </div>
                        </TransformComponent>
                    </TransformWrapper>

                    {/* Esto es para hacerlo sin saltos, pero no es como en RR ---> wheel={{ step: 0.9 }} */}


                </div>

            </div>


        </div>

    );
};