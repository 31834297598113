import GreenPremiumBadge from './static/PremiumBadge.png';
import GoldenPremiumBadge from './static/GoldenPremiumBadge.png';
import styles from './premiumBadge.module.css';

export const RenderPremiumBadge = ({ activeSubscriptionType }) => {
  if (activeSubscriptionType === 'GREEN') {
    return <img className={styles.premiumBadge} alt='' src={GreenPremiumBadge} />;
  } else if (activeSubscriptionType === 'GOLD') {
    return <img className={styles.premiumBadge} alt='' src={GoldenPremiumBadge} />;
  }
};