import React from 'react'
import styles from './fixedheader.module.css'
import { useNavigate } from 'react-router-dom';
import BackArrowIcon from '../../../Components/Assets/BackArrow.svg'

// Hará falta un padding-top de 52px para que el fixedHeader no tape el contenido
export const FixedHeader = ({
    rightContent, // Contenido JSX para el rightContainer
    title,     // Título (string)
    subtitle,  // Subtítulo (string)
    cotitle,   // Cotítulo (string)
}) => {

    const navigate = useNavigate();

    return (
        <section className={styles.fixedHeader}>
            <button className={styles.goBack} onClick={() => navigate(-1)}>
                <img src={BackArrowIcon} alt="back" />
            </button>
            <div className={styles.titlesContainer}>
                <h1 className={styles.headerTitle}>{title} <span>{cotitle}</span></h1>
                <p className={styles.headerSubtitle}>{subtitle}</p>
            </div>

            <div className={styles.rightContainer}>
                {rightContent}
            </div>
        </section>
    )
}
