import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import VerifiedIcon from '@mui/icons-material/Verified';
import InfoIcon from '@mui/icons-material/Info';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import ContactlessIcon from '@mui/icons-material/Contactless';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { EmailOutlined } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

export const moreNavigationMenu=[
    {
        title:"Market",
        filledIcon:<ContactlessIcon style={{ fontSize: '1.7rem' }}/>,
        outlinedIcon:<ContactlessOutlinedIcon style={{ fontSize: '1.7rem' }}/>,
        path:"/market"
    },
    {
        title:"Organizations",
        filledIcon:<AccountBalanceIcon style={{ fontSize: '1.7rem' }}/>,
        outlinedIcon:<AccountBalanceOutlinedIcon style={{ fontSize: '1.7rem' }}/>,
        path:"/org"
    },
    {
        title:"Conflict",
        filledIcon:<LocalFireDepartmentIcon style={{ fontSize: '1.7rem' }}/>,
        outlinedIcon:<LocalFireDepartmentOutlinedIcon style={{ fontSize: '1.7rem' }}/>,
        path:"/conflict"
    },
    {
        title:"Personal",
        filledIcon:<PersonOutlineOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<PersonOutlineOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/personal"
    },
    {
        title:"Knowledge",
        filledIcon:<AutoAwesomeIcon style={{ fontSize: '1.7rem' }}/>,
        outlinedIcon:<AutoAwesomeOutlinedIcon style={{ fontSize: '1.7rem' }}/>,
        path:"/knowledge"
    },
    {
        title:"University",
        filledIcon:<SchoolOutlinedIcon style={{ fontSize: '1.7rem' }}/>,
        outlinedIcon:<SchoolOutlinedIcon style={{ fontSize: '1.7rem' }}/>,
        path:"/university"
    },
    {
        title:"Messages",
        filledIcon:<EmailIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<EmailOutlined style={{ fontSize: '1.8rem' }}/>,
        path:"/messages"
    },
    {
        title:"Email",
        filledIcon:<AttachEmailIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<AttachEmailOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/email"
    },
    {
        title:"Premium",
        filledIcon:<VerifiedIcon style={{ fontSize: '1.7rem' }}/>,
        outlinedIcon:<VerifiedOutlinedIcon style={{ fontSize: '1.7rem' }}/>,
        path:"/premium"
    },
    {
        title:"Ads",
        filledIcon:<AdsClickIcon style={{ fontSize: '1.7rem' }}/>,
        outlinedIcon:<AdsClickIcon style={{ fontSize: '1.7rem' }}/>,
        url:"https://ads.worldorder.app"
    },
    {
        title:"Docs",
        filledIcon:<InfoIcon style={{ fontSize: '1.7rem' }}/>,
        outlinedIcon:<InfoOutlinedIcon style={{ fontSize: '1.7rem' }}/>,
        url:"https://docs.worldorder.app"
    },
    {
        title:"Settings",
        filledIcon:<SettingsOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        outlinedIcon:<SettingsOutlinedIcon style={{ fontSize: '1.8rem' }}/>,
        path:"/settings"
    },
]