import React, { useEffect, useState } from 'react'
import styles from './organizations.module.css'
import { useNavigate } from 'react-router-dom';
import { api } from '../../Config/api'
import { formatText } from '../../Utils/capitalizeFirtsLetter';

export const Organizations = () => {
  const navigate = useNavigate();

  const [organizationsData, setOrganizationsData] = useState();

  useEffect(() => {
    // Traemos los datos para comprobar si el player tiene aprendido el conocimiento requerido de Young Idealist,
    // si está fundando otra organization, el lugar donde tendría la sede la nueva organization y la arauzita necesaria
    const initializeComponent = async () => {
      try {
        const response = await api.get('/api/org/menu');
        setOrganizationsData(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Failed to fetch foundation requirements:', error);
      }
    };

    initializeComponent();
  }, []);

  return (
    <div className={styles.pageContainer}>

      <div className={styles.mainContent}>

        <div className={styles.sidebar}>
          <p><i>Nunca pertenecería a un club que admitiera como socio a alguien como yo.</i></p>
          <p><a href='https://es.wikipedia.org/wiki/Groucho_Marx' target='_blank' rel='noopener noreferrer'><strong>- Groucho Marx -</strong></a></p>
        </div>

        <div className={styles.organizationsContainer}>

          <div className={styles.myOrganizations}>

            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.myOrganizationsTitle}>My organizations</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Here you can review the organizations of which you are a member or hold a position of responsibility.</p>
            </div>

            {organizationsData && organizationsData?.organizationPosition.map((organization) => (

              <div className={styles.foundingItem} onClick={() => navigate(organization?.url)}>
                <p className={styles.organizationType}>{formatText(organization?.positionTitleType)}</p>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <img src={organization?.logoUrl} alt='Org' />
                  <div className={styles.foundingItemInfo}>
                    <p className={styles.foundingItemName}>{organization?.name}</p>
                    <p className={styles.foundingItemUsername}>{organization?.username}</p>
                  </div>
                </div>
              </div>

            ))}

            {organizationsData && organizationsData?.associationList.map((organization) => (

              <div className={styles.foundingItem} onClick={() => navigate(organization?.url)}>
                <p className={styles.organizationType}>Affiliated</p>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <img src={organization?.logoUrl} alt='Org' />
                  <div className={styles.foundingItemInfo}>
                    <p className={styles.foundingItemName}>{organization?.name}</p>
                    <p className={styles.foundingItemUsername}>{organization?.username}</p>
                  </div>
                </div>
              </div>

            ))}

          </div>

          <div className={styles.myOrganizations}>

            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.myOrganizationsTitle}>Create organization</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Here you can review the founding process of organizations in which you are involved.</p>
            </div>

            {organizationsData && organizationsData?.potentialCoFounderOrganizations.map((organization) => (

              <div className={styles.foundingItem} onClick={() => navigate(organization?.founding?.organization?.url)}>
                <p className={styles.organizationType}>{formatText(organization?.founding?.organization?.organizationType)}</p>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <img src={organization?.founding?.organization?.logoUrl} alt='Org' />
                  <div className={styles.foundingItemInfo}>
                    <p className={styles.foundingItemName}>{organization?.founding?.organization?.name}</p>
                    <p className={styles.foundingItemUsername}>{organization?.founding?.organization?.username}</p>
                  </div>
                </div>
              </div>

            ))}


            <div className={styles.buttons}>

              <button
                className={styles.myOrganizationsButton}
                onClick={() => navigate('foundation')}
              >
                Found organization
              </button>

            </div>

          </div>

          <div className={styles.myOrganizations}>

            <div className={styles.titleAndRequiredLevel}>
              <p className={styles.myOrganizationsTitle}>LinkedOut</p>
            </div>
            <div className={styles.contentWrapper}>
              <p>Ready for the adventure of turning your fast-food experience into a résumé? Relax. Leading three cashiers at night is basically running a tech startup. “Flexibility under pressure”? We call it dynamic existential crisis management.</p>
              <p>Company job offer search engine.</p>
            </div>



            <div className={styles.buttons}>

              <button
                className={styles.myOrganizationsButton}
                onClick={() => navigate('linkedout')}
              >
                Search for jobs
              </button>

            </div>

          </div>





        </div>
      </div>

    </div>
  );
};

// Falta añadir el modal para cuando no puede fundar un tipo de organization porque no tiene adquirido el conocimiento correspondiente