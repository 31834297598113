import React, { useEffect, useState } from 'react'
import styles from './politicalparty.module.css'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '../../../Config/api';
import { formatDateToReadableString } from '../../../Utils/DurationToDateTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VerifiedOrganizationBadge from './VerifiedOrganizationBadge';
import GroupsIcon from '@mui/icons-material/Groups';
import OrganizationStatus from '../../Utils/OrganizationStatus/OrganizationStatus';
import { replaceUnderscoresWithSpaces } from '../../../Utils/capitalizeFirtsLetter';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { RenderPremiumBadge } from '../../Utils/RenderPremiumBadge/RenderPremiumBadge';
import { Tab, Tabs } from '../../../Utils/Components/Tabs/Tabs';

export const PoliticalPartyProfile = () => {

    const navigate = useNavigate();

    const [loadingProfile, setLoadingProfile] = useState(true);

    const { username } = useParams();

    const [politicalPartyProfile, setPoliticalPartyProfile] = useState();

    const [peopleSelection, setPeopleSelection] = useState('Committee');

    const handleBack = () => navigate(-1);

    useEffect(() => {

        setLoadingProfile(true);

        const initializeComponent = async () => {
            try {
                const response = await api.get(`api/pp/${username}`);
                setPoliticalPartyProfile(response.data);
                if (response.status == 200) {
                    setLoadingProfile(false)
                    console.log(response.data)
                }
            } catch (error) {
                console.error('Failed to fetch political party profile:', error);
            }
        };

        initializeComponent();

    }, [username]);


    return (
        <div className={styles.mainContent}>
            {!loadingProfile &&
                <div style={{ border: '1px solid #2c2f32', minHeight: '100vh', paddingBottom: '70px' }}>
                    <section className={styles.fixedHeader}>
                        <KeyboardBackspaceIcon className={styles.arrowIcon} onClick={handleBack} />
                        <h1 className={styles.headerTitle}>{politicalPartyProfile?.name}</h1>
                        <div className={styles.rightContainer}>

                        </div>
                    </section>

                    <img className={styles.backgroundImage} src={politicalPartyProfile?.backgroundImageUrl} alt='' />

                    <div className={styles.profileHeader}>
                        <img className={styles.profileImage} src={politicalPartyProfile?.logoUrl} alt='' />
                    </div>

                    <section className={styles.profileInfo}>
                        <div className={styles.userDetails}>
                            <div className={styles.userInfo}>
                                <h1 className={styles.name}>{politicalPartyProfile?.name}</h1>
                                <VerifiedOrganizationBadge />
                            </div>
                            <h1 className={styles.username}>{politicalPartyProfile?.username}</h1>
                        </div>

                        <div className={styles.userDetails}>
                            <p>{politicalPartyProfile?.description}</p>

                            <div className={styles.userStats}>

                                {!politicalPartyProfile?.isFounding ?
                                    <>
                                        {/* TODO: en el hover se pone un subrayado, y al hacer clic lleva a una pantallita en la que se ven los fundadores, común para todas las organizations */}
                                        <div className={styles.userStat}>
                                            <CalendarMonthIcon className={styles.userStatsIcons} />
                                            <p>Founded on {politicalPartyProfile?.foundingDate && formatDateToReadableString(politicalPartyProfile.foundingDate)}</p>
                                        </div>
                                        <div className={styles.userStat}>
                                            <GroupsIcon className={styles.userStatsIcons} />
                                            <p>145,892 affiliates</p>
                                        </div>
                                        <OrganizationStatus status={politicalPartyProfile?.legality} />
                                    </>
                                    : <div className={styles.pendingContainer}>
                                        <p className={styles.statusPending}>(Pending to be founded)</p>
                                    </div>}
                            </div>

                        </div>
                    </section>

                    <section className={styles.tabContainer}>
                        <Tabs>
                            <Tab label="Profile">
                                <div className={styles.profileTab}>

                                    {politicalPartyProfile?.anthemUrl && <div className={styles.profileElement}>
                                        <p>Anthem:</p>

                                        <div className={styles.profileElementContent}>

                                            {politicalPartyProfile?.anthemUrl &&
                                                <audio controls>
                                                    <source src={politicalPartyProfile?.anthemUrl} type="audio/mpeg" />
                                                    <source src={politicalPartyProfile?.anthemUrl} type="audio/ogg" />
                                                    Your browser does not support the audio element.
                                                </audio>}


                                        </div>
                                    </div>}

                                    {politicalPartyProfile?.positions
                                        ?.filter((association) => association.positionType === 'CHAIRPERSON')
                                        .map((association) => (
                                            <div className={styles.profileElement} onClick={() => navigate(`/profile/user/${association?.player?.username}`)}>
                                                <p>Chairperson:</p>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                    <div
                                                        key={association?.player?.username} // Usa una clave única
                                                        className={styles.nationalityListElement}
                                                        onClick={() => navigate(association?.url)}
                                                    >
                                                        <img
                                                            src={association?.player?.profileImageUrl}
                                                            alt="State shield"
                                                            style={{ borderRadius: '6px' }}
                                                        />
                                                        <div className={styles.nationalityInfo}>
                                                            <p>{association?.player?.name}</p>
                                                            <p style={{ color: '#767b80', fontWeight: 'normal' }}>
                                                                {association?.player?.username}
                                                            </p>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}

                                    <div className={styles.profileElement}>
                                        <p>Headquarters:</p>

                                        <div>

                                            <div className={styles.cityPreview} onClick={() => navigate(`/profile/city/${politicalPartyProfile?.headquartersCity?.username}`)}>
                                                <div className={styles.imageContainer}>
                                                    <img src={politicalPartyProfile?.headquartersCity?.shieldUrl} />
                                                </div>
                                                <p>{politicalPartyProfile?.headquartersCity?.name}</p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>State:</p>

                                        <div>

                                            <div className={styles.cityPreview} onClick={() => navigate(`/profile/state/${politicalPartyProfile?.headquartersCity?.region?.stateOwner?.username}`)}>
                                                <div className={styles.imageContainer}>
                                                    <img src={politicalPartyProfile?.headquartersCity?.region?.stateOwner?.shieldUrl} />
                                                </div>
                                                <p>{politicalPartyProfile?.headquartersCity?.region?.stateOwner?.name}</p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className={styles.profileElement}>
                                        <p>Ideologies:</p>
                                        <div>
                                            <ul>
                                                <li><span>Main:</span> <strong>{replaceUnderscoresWithSpaces(politicalPartyProfile?.ideology?.mainIdeology)}</strong></li>
                                                <li><span>Secondary:</span> <strong>{politicalPartyProfile?.ideology?.secondaryIdeology == 'NATIONALISM' && politicalPartyProfile?.ideology?.secondaryIdeology}{replaceUnderscoresWithSpaces(politicalPartyProfile?.ideology?.secondaryIdeology)}</strong></li>
                                                <li><span>Economic:</span> <strong>{replaceUnderscoresWithSpaces(politicalPartyProfile?.ideology?.economicIdeology)}</strong></li>
                                                <li><span>Form of Government:</span> <strong>{replaceUnderscoresWithSpaces(politicalPartyProfile?.ideology?.formOfGovernmentIdeology)}</strong></li>
                                                <li><span>Power Structure:</span> <strong>{replaceUnderscoresWithSpaces(politicalPartyProfile?.ideology?.powerStructureIdeology)}</strong></li>
                                            </ul>
                                        </div>
                                    </div>

                                    {politicalPartyProfile?.religionDenomination &&
                                        <div className={styles.profileElement}>
                                            <p>Religion:</p>
                                            <div>
                                                <div className={styles.cityPreview}>
                                                    <p>{replaceUnderscoresWithSpaces(politicalPartyProfile?.religionDenomination)}</p>
                                                </div>
                                            </div>
                                        </div>}





                                    <div className={styles.buttonsSection}>

                                        <button className={styles.button}>
                                            <span>Elections</span>
                                        </button>
                                        <button className={styles.territorialOrganizationButton}>Divisions</button>


                                    </div>

                                </div>
                            </Tab>
                            <Tab label="People">
                                <div className={styles.quarterDropdown}>
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <InputLabel
                                            id="demo-select-small-label"
                                            sx={{
                                                color: '#71767B', // Color del label por defecto
                                                '&.Mui-focused': {
                                                    color: '#099f5d', // Cambia el color del label cuando el Select está enfocado
                                                },
                                            }} // Cambia el color del InputLabel a blanco
                                        >
                                            Type
                                        </InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={peopleSelection}
                                            label="Type"
                                            onChange={(event) => setPeopleSelection(event.target.value)}
                                            sx={{
                                                backgroundColor: 'black',
                                                color: 'white', // Cambia el color del texto seleccionado a blanco
                                                '& .MuiSelect-select': {
                                                    backgroundColor: 'black', // Color de fondo del select
                                                    color: 'white', // Color del valor seleccionado
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#767b80', // Borde blanco
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#767b80', // Borde blanco al pasar el mouse
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#767b80', // Borde blanco cuando está enfocado
                                                },
                                                '& .MuiSelect-icon': {
                                                    color: '#767b80', // Cambia el color del icono del "triangulito"
                                                },
                                            }}
                                        >
                                            <MenuItem value={'Committee'}>Committee</MenuItem>
                                            <MenuItem value={'Affiliates'}>Affiliates</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                {peopleSelection == 'Committee' && politicalPartyProfile?.positions
                                    ?.filter((position) => position.positionType === 'COMMITTEE_MEMBER')
                                    .map((position) => (
                                        <div className={styles.cofoundersList}>
                                            <div className={styles.cofounderItem} key={position?.username} onClick={() => navigate(`/profile/user/${position?.player?.username}`)}>
                                                <div className={styles.cofounderInfo}>
                                                    <img
                                                        src={position?.player?.profileImageUrl}
                                                        alt="Co-founder avatar"
                                                        className={styles.cofounderAvatar}
                                                    />
                                                    <div className={styles.cofounderDetails}>
                                                        <div>
                                                            <p className={styles.name}>{position?.player?.name}</p>
                                                            <RenderPremiumBadge activeSubscriptionType={position?.premiumSubscription?.activeSubscriptionType}/>
                                                        </div>

                                                        <p className={styles.username}>{position?.player?.username}</p>
                                                    </div>
                                                </div>


                                                <div className={styles.aboutContainer}>
                                                    <p className={styles.description}>{position?.about}</p>
                                                </div>

                                            </div>
                                        </div>
                                    ))}

                                {peopleSelection == 'Affiliates' && politicalPartyProfile?.associationList
                                    ?.map((association) => (
                                        <div className={styles.cofoundersList}>
                                            <div className={styles.cofounderItem} key={association?.username} onClick={() => navigate(`/profile/user/${association?.player?.username}`)}>
                                                <div className={styles.cofounderInfo}>
                                                    <img
                                                        src={association?.player?.profileImageUrl}
                                                        alt="Co-founder avatar"
                                                        className={styles.cofounderAvatar}
                                                    />
                                                    <div className={styles.cofounderDetails}>
                                                        <div>
                                                            <p className={styles.name}>{association?.player?.name}</p>
                                                            <RenderPremiumBadge activeSubscriptionType={association?.premiumSubscription?.activeSubscriptionType}/>
                                                        </div>

                                                        <p className={styles.username}>{association?.player?.username}</p>
                                                    </div>
                                                </div>


                                                <div className={styles.aboutContainer}>
                                                    <p className={styles.description}>{association?.about}</p>
                                                </div>

                                            </div>
                                        </div>)
                                    )
                                }
                            </Tab>
                            <Tab label="Posts">
                                <div>Replies</div>
                            </Tab>
                            <Tab label="Structures">
                                <p>Coming... not soon</p>
                            </Tab>
                            <Tab label="Rules">
                                <div className={styles.profileTab}>
                                    <div className={styles.profileElement}>
                                        <p>Chairperson Elections:</p>
                                        <div>
                                            <div className={styles.cityPreview}>
                                                <p>{replaceUnderscoresWithSpaces(politicalPartyProfile?.internalRules?.chairpersonElections)}</p>
                                            </div>
                                        </div>

                                    </div>
                                    {politicalPartyProfile?.internalRules?.chairpersonTermOfOffice &&
                                        <div className={styles.profileElement}>
                                            <p>Chairperson TOF:</p>
                                            <div>
                                                <div className={styles.cityPreview}>
                                                    <p>{replaceUnderscoresWithSpaces(politicalPartyProfile?.internalRules?.chairpersonTermOfOffice)}</p>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                    <div className={styles.profileElement}>
                                        <p>Chairperson Removal:</p>
                                        <div>
                                            <div className={styles.cityPreview}>
                                                <p>{replaceUnderscoresWithSpaces(politicalPartyProfile?.internalRules?.chairpersonRemoval)}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={styles.profileElement}>
                                        <p>Committee Elections:</p>
                                        <div>
                                            <div className={styles.cityPreview}>
                                                <p>{replaceUnderscoresWithSpaces(politicalPartyProfile?.internalRules?.committeeElections)}</p>
                                            </div>
                                        </div>

                                    </div>
                                    {politicalPartyProfile?.internalRules?.committeeTermOfOffice &&
                                        <div className={styles.profileElement}>
                                            <p>Committee TOF:</p>
                                            <div>
                                                <div className={styles.cityPreview}>
                                                    <p>{replaceUnderscoresWithSpaces(politicalPartyProfile?.internalRules?.committeeTermOfOffice)}</p>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                    <div className={styles.profileElement}>
                                        <p>Committee Removal:</p>
                                        <div>
                                            <div className={styles.cityPreview}>
                                                <p>{replaceUnderscoresWithSpaces(politicalPartyProfile?.internalRules?.committeeRemoval)}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={styles.profileElement}>
                                        <p>Electoral Candidates:</p>
                                        <div>
                                            <div className={styles.cityPreview}>
                                                <p>{replaceUnderscoresWithSpaces(politicalPartyProfile?.internalRules?.electionOfElectoralCandidates)}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={styles.profileElement}>
                                        <p>Coalition Building:</p>
                                        <div>
                                            <div className={styles.cityPreview}>
                                                <p>{replaceUnderscoresWithSpaces(politicalPartyProfile?.internalRules?.coalitionBuilding)}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={styles.profileElement}>
                                        <p>Membership:</p>
                                        <div>
                                            <div className={styles.cityPreview}>
                                                <p>{replaceUnderscoresWithSpaces(politicalPartyProfile?.internalRules?.membership)}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={styles.profileElement}>
                                        <p>Expulsions:</p>
                                        <div>
                                            <div className={styles.cityPreview}>
                                                <p>{replaceUnderscoresWithSpaces(politicalPartyProfile?.internalRules?.expulsions)}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={styles.profileElement}>
                                        <p>Changes:</p>
                                        <div>
                                            <div className={styles.cityPreview}>
                                                <p>{replaceUnderscoresWithSpaces(politicalPartyProfile?.internalRules?.changes)}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Tab>



                        </Tabs>
                    </section>

                    <section>
                        {/* Para los modal */}
                    </section>
                </div>
            }


        </div >
    );
};
