import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './settingspage.module.css';
import { useDispatch } from 'react-redux';
import { logout } from '../../Store/Auth/Action';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { api, ensureAuthorizationHeader } from '../../Config/api';
import BackArrowIcon from '../Assets/BackArrow.svg'

const AccountInfo = ({ handleLogout, email, role }) => (
  <div className={styles.contentSection}>
    <h2>Your account</h2>
    <div className={styles.gapText}>
      <div>
        <p>Below is the email linked to your World Order account.</p>
        <p className={styles.accountEmail}>{email}</p>
      </div>
      <div>
        <p>Below is your user role. To access the position of administrator in your language you must be elected by the community. To be eligible for the role of moderator, your application must be validated by the administrator of your language.</p>
        <p className={styles.accountEmail}>{role}</p>
      </div>
      <button className={styles.adminBoardButton} onClick={() => handleLogout()}>View admin board</button>
    </div>



    <button className={styles.logoutButton} onClick={() => handleLogout()}>Logout</button>
  </div>
);

const ChangePassword = () => (
  <div className={styles.contentSection}>
    <h2>Change your password</h2>
    <p>You will receive an email with a link to reset your account password. The link expires in 30 minutes.</p>
    <button className={styles.changePasswordButton} onClick={() => { }}>Change your password</button>
  </div>
);

const PremiumInfo = () => (
  <div className={styles.contentSection}>
    <h2>WO Premium</h2>
    <p>Here you can check your active annual premium subscription and see the benefits of the different premium plans.</p>
  </div>
);

const DownloadData = () => (
  <div className={styles.contentSection}>
    <h2>Download a file with your data</h2>
    <p>You can download a PDF file containing data related to your World Order account.</p>
  </div>
);

const DeleteAccount = () => (
  <div className={styles.contentSection}>
    <h2>Delete your account</h2>
    <div className={styles.gapText}>
      <p>Deleting your account is an irreversible action. You will receive an email with a link to begin the account deletion process. The link expires in 15 minutes.</p>
      <p>Once the process has started, you will have up to one week to cancel the deletion. To cancel the process, simply log in to your account from any device.</p>
    </div>
  </div>
);

export const SettingsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 829);

  // Inicializa showMenu correctamente
  const [showMenu, setShowMenu] = useState(
    window.innerWidth >= 829 || !location.pathname.includes('/settings/')
  );

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 829;
      setIsMobile(mobile);

      // Ajusta showMenu en función del ancho de la pantalla y la ruta actual
      if (mobile) {
        setShowMenu(!location.pathname.includes('/settings/'));
      } else {
        setShowMenu(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [location.pathname]);

  const [settingsData, setSettingsData] = useState();

  useEffect(() => {
    const fetchSettingsData = async () => {

      try {
        ensureAuthorizationHeader();
        const { data } = await api.get(
          `/api/user/settings`
        );
        setSettingsData(data); // Maneja la respuesta de la API aquí

      } catch (error) {
        console.error("Error fetching settings data:", error);
      } finally {

      }
    };

    fetchSettingsData();
  }, []);

  const menuItems = [
    { path: 'account', label: 'Your account' },
    { path: 'password', label: 'Change your password' },
    { path: 'premium', label: 'Premium' },
    { path: 'download', label: 'Download your data' },
    { path: 'delete', label: 'Delete your account' },
  ];

  const handleMenuItemClick = (path) => {
    navigate(`/settings/${path}`);
    if (isMobile) setShowMenu(false); // Oculta el menú en móvil al hacer clic en un elemento
  };

  const handleBackClick = () => {
    if (isMobile) {
      setShowMenu(true); // Muestra el menú en móvil al hacer clic en "Atrás"
      navigate('/settings');
    }
  };

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.socialContainer}>
        {(!isMobile || showMenu) && (
          <nav className={styles.settingsMenu}>
            <h1 className={styles.menuTitle}>Configuración</h1>
            <ul className={styles.menuList}>
              {menuItems.map((item) => (
                <li key={item.path} className={styles.menuItem}>
                  <Link
                    to={`/settings/${item.path}`}
                    className={styles.menuLink}
                    onClick={() => handleMenuItemClick(item.path)}
                  >
                    {item.label}
                    <KeyboardArrowRightIcon className={styles.iconColor} />
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}

        {(!isMobile || !showMenu) && (
          <div className={styles.settingsContent}>
            <Routes>
              <Route
                path="account"
                element={
                  <div className={styles.contentSection}>
                    <div className={styles.contentHeader}>
                      {isMobile && (
                        <button className={styles.goBack} onClick={handleBackClick}>
                          <img src={BackArrowIcon} alt="back" />
                        </button>
                      )}
                      <h2>Your account</h2>
                    </div>
                    <div className={styles.gapText}>
                      <div>
                        <p>Below is the email linked to your World Order account.</p>
                        <p className={styles.accountEmail}>{settingsData?.mail}</p>
                      </div>
                      <div>
                        <p>Below is your user role. To access the position of administrator in your language you must be elected by the community. To be eligible for the role of moderator, your application must be validated by the administrator of your language.</p>
                        <p className={styles.accountEmail}>{settingsData?.role}</p>
                      </div>
                      <button className={styles.adminBoardButton} onClick={() => handleLogout()}>
                        View admin board
                      </button>
                    </div>
                    <button className={styles.logoutButton} onClick={() => handleLogout()}>
                      Logout
                    </button>
                  </div>
                }
              />
              <Route
                path="password"
                element={
                  <div className={styles.contentSection}>
                    <div className={styles.contentHeader}>
                      {isMobile && (
                        <button className={styles.goBack} onClick={handleBackClick}>
                          <img src={BackArrowIcon} alt="back" />
                        </button>
                      )}
                      <h2>Change your password</h2>
                    </div>
                    <p>You will receive an email with a link to reset your account password. The link expires in 30 minutes.</p>
                    <button className={styles.changePasswordButton} onClick={() => { }}>
                      Change your password
                    </button>
                  </div>
                }
              />
              <Route
                path="premium"
                element={
                  <div className={styles.contentSection}>
                    <div className={styles.contentHeader}>
                      {isMobile && (
                        <button className={styles.goBack} onClick={handleBackClick}>
                          <img src={BackArrowIcon} alt="back" />
                        </button>
                      )}
                      <h2>WO Premium</h2>
                    </div>
                    <p>Here you can check your active annual premium subscription and see the benefits of the different premium plans.</p>
                  </div>
                }
              />
              <Route
                path="download"
                element={
                  <div className={styles.contentSection}>
                    <div className={styles.contentHeader}>
                      {isMobile && (
                        <button className={styles.goBack} onClick={handleBackClick}>
                          <img src={BackArrowIcon} alt="back" />
                        </button>
                      )}
                      <h2>Download a file with your data</h2>
                    </div>
                    <p>You can download a PDF file containing data related to your World Order account.</p>
                  </div>
                }
              />
              <Route
                path="delete"
                element={
                  <div className={styles.contentSection}>
                    <div className={styles.contentHeader}>
                      {isMobile && (
                        <button className={styles.goBack} onClick={handleBackClick}>
                          <img src={BackArrowIcon} alt="back" />
                        </button>
                      )}
                      <h2>Delete your account</h2>
                    </div>
                    <div className={styles.gapText}>
                      <p>Deleting your account is an irreversible action. You will receive an email with a link to begin the account deletion process. The link expires in 15 minutes.</p>
                      <p>Once the process has started, you will have up to one week to cancel the deletion. To cancel the process, simply log in to your account from any device.</p>
                    </div>


                  </div>
                }
              />
            </Routes>
          </div>
        )}
      </div>
    </div>
  );
};