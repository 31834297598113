import { Route, Routes } from 'react-router-dom';
import styles from './container.module.css'
import SocialRightPart from '../SocialPage/RightPart/SocialRightPart';
import { PlayerRankingPage } from '../Ranking/Player/PlayerRankingPage';

// A partir de ahora emplear este componente como contenedor cuando queremos la estructura con margenes laterales y menú lateral derecho
const Container = () => {
    return (
        <div className={styles.socialContainer}>
            <div className={styles.borderWrapper}>
                <main className={styles.feedContainer}>
                    <Routes>
                        <Route path="players" element={<PlayerRankingPage />} />
                    </Routes>
                </main>
            </div>
            <aside className={styles.socialRightPart}>
                <SocialRightPart />
            </aside>
        </div>
    );
};

export default Container