import React, { useState } from 'react'
import { Avatar, Button, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import GreenPremiumBadge from '../static/PremiumBadge.png';
import GoldenPremiumBadge from '../static/GoldenPremiumBadge.png';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import PostReplyModal from '../PostReplyModal/PostReplyModal';
import { useDispatch } from 'react-redux';
import { likeUnlikePost, repostUnrepost } from '../../../Store/Post/Action';
import styles from './publicationcard.module.css'

import { CommentIcon } from './Static/CommentIcon';
import { RepostIcon } from './Static/RepostIcon';
import { OutlinedLikeIcon } from './Static/OutlinedLikeIcon';
import { FilledLikeIcon } from './Static/FilledLikeIcon';
import { VisualizationsIcon } from './Static/VisualizationsIcon';
import { ShareIcon } from './Static/ShareIcon';
import { getYouTubeVideoId } from '../../../Utils/getYoutubeVideoId';
import { RenderPremiumBadge } from '../../Utils/RenderPremiumBadge/RenderPremiumBadge';


// Hacer que se muestre o no la insignea de verificado según si tiene el premium o no y qué tipo

const PublicationCard = ({ item, openLimitPostsModal, isFeed }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [openReplyModal, setOpenReplyModal] = React.useState(false);

  const handleOpenReplyModal = () => {
    // Si se habre desde el feed, entonces que haga esta navegación, si no es entonces no, no
    if (isFeed) {
      navigate('/social/post');
    }
    setOpenReplyModal(true)
  };
  const handleCloseReplyModal = () => setOpenReplyModal(false);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }


  const handleDeletePublication = () => {
    console.log("delete publication")
    handleClose();
  }


  const handleCreateRePost = () => {
    dispatch(repostUnrepost(item?.id))
    console.log("handle create repost")
  }

  const handleLikePost = () => {
    dispatch(likeUnlikePost(item?.id))
    console.log("handle like post");
  }

  const publishedAt = new Date(item?.publishedAt);
  const currentTime = new Date();
  // Diferencia en segundos
  const timeDifferenceInSeconds = Math.floor((currentTime - publishedAt) / 1000);
  let displayTime;
  if (timeDifferenceInSeconds < 60) {
    // Menos de un minuto
    displayTime = `${timeDifferenceInSeconds}s`;
  } else if (timeDifferenceInSeconds < 3600) {
    // Menos de una hora
    const minutes = Math.floor(timeDifferenceInSeconds / 60);
    displayTime = `${minutes}m`;
  } else if (timeDifferenceInSeconds < 86400) {
    // Menos de un día
    const hours = Math.floor(timeDifferenceInSeconds / 3600);
    displayTime = `${hours}h`;
  } else if (timeDifferenceInSeconds < 2592000) {
    // Días o más
    const days = Math.floor(timeDifferenceInSeconds / 86400);
    displayTime = `${days}d`;
  } else if (timeDifferenceInSeconds < 31536000) {
    // Menos de un año
    const months = Math.floor(timeDifferenceInSeconds / 2592000);
    displayTime = `${months}mo`;
  } else {
    // Años o más
    const years = Math.floor(timeDifferenceInSeconds / 31536000);
    displayTime = `${years}y`;
  }

  // TODO: Esto de aquí abajo extraerlo a una función que se le pasa el número y devuelve un string con el dato más la letra si es necesario
  let numberOfLikes = item?.numberOfLikes;
  let displayNumberOfLikes;

  if (numberOfLikes == 0) {
    displayNumberOfLikes = '';
  }
  else if (numberOfLikes < 1000) {
    displayNumberOfLikes = `${numberOfLikes}`;
  } else if (numberOfLikes < 1000000) {
    const roundedLikes = Math.floor(numberOfLikes / 1000);
    const decimalPart = Math.round((numberOfLikes % 1000) / 100);
    displayNumberOfLikes = decimalPart > 0 ? `${roundedLikes}.${decimalPart}K` : `${roundedLikes}K`;
  } else {
    const roundedLikes = Math.floor(numberOfLikes / 1000000);
    const decimalPart = Math.round((numberOfLikes % 1000000) / 100000);
    displayNumberOfLikes = decimalPart > 0 ? `${roundedLikes}.${decimalPart}M` : `${roundedLikes}M`;
  }

  let numberOfReplies = item?.numberOfReplies;
  let displayNumberOfReplies;

  if (numberOfReplies == 0) {
    displayNumberOfReplies = '';
  } else if (numberOfReplies < 1000) {
    displayNumberOfReplies = `${numberOfReplies}`;
  } else if (numberOfReplies < 1000000) {
    const roundedReplies = Math.floor(numberOfReplies / 1000);
    const decimalPart = Math.round((numberOfReplies % 1000) / 100);
    displayNumberOfReplies = decimalPart > 0 ? `${roundedReplies}.${decimalPart}K` : `${roundedReplies}K`;
  } else {
    const roundedReplies = Math.floor(numberOfReplies / 1000000);
    const decimalPart = Math.round((numberOfReplies % 1000000) / 100000);
    displayNumberOfReplies = decimalPart > 0 ? `${roundedReplies}.${decimalPart}M` : `${roundedReplies}M`;
  }

  let numberOfReposts = item?.numberOfReposts;
  let displayNumberOfReposts;

  if (numberOfReposts == 0) {
    displayNumberOfReposts = '';
  } else if (numberOfReposts < 1000) {
    displayNumberOfReposts = `${numberOfReposts}`;
  } else if (numberOfReposts < 1000000) {
    const roundedReposts = Math.floor(numberOfReposts / 1000);
    const decimalPart = Math.round((numberOfReposts % 1000) / 100);
    displayNumberOfReposts = decimalPart > 0 ? `${roundedReposts}.${decimalPart}K` : `${roundedReposts}K`;
  } else {
    const roundedReposts = Math.floor(numberOfReposts / 1000000);
    const decimalPart = Math.round((numberOfReposts % 1000000) / 100000);
    displayNumberOfReposts = decimalPart > 0 ? `${roundedReposts}.${decimalPart}M` : `${roundedReposts}M`;
  }

  let numberOfVisualizations = item?.visualizations;
  let displayNumberOfVisualizations;
  if (numberOfVisualizations < 1000) {
    displayNumberOfVisualizations = `${numberOfVisualizations}`;
  } else if (numberOfVisualizations < 1000000) {
    const roundedVisualizations = Math.floor(numberOfVisualizations / 1000);
    const decimalPart = Math.round((numberOfVisualizations % 1000) / 100);
    displayNumberOfVisualizations = decimalPart > 0 ? `${roundedVisualizations}.${decimalPart}K` : `${roundedVisualizations}K`;
  } else {
    const roundedVisualizations = Math.floor(numberOfVisualizations / 1000000);
    const decimalPart = Math.round((numberOfVisualizations % 1000000) / 100000);
    displayNumberOfVisualizations = decimalPart > 0 ? `${roundedVisualizations}.${decimalPart}M` : `${roundedVisualizations}M`;
  }



  const [mouseOnCommentIcon, setMouseOnCommentIcon] = useState(false);
  const [mouseOnRepostIcon, setMouseOnRepostIcon] = useState(false);
  const [mouseOnLikeIcon, setMouseOnLikeIcon] = useState(false);
  const [mouseOnVisualizationsIcon, setMouseOnVisualizationsIcon] = useState(false);
  const [mouseOnShareIcon, setMouseOnShareIcon] = useState(false);



  return (
    <div className={`${styles.publicationCardContainer} ${isFeed ? styles.borderInline : undefined}`}>

      {item?.repostInfo ? <div className={styles.reposted}>
        <RepeatOutlinedIcon className={styles.repostTopIcon} />
        <span className={styles.repostText} onClick={() => navigate(`profile/user/${item?.repostInfo?.repostAuthUsername}`)}>{item?.repostInfo?.repostAuthorName} reposted</span>
      </div> : ''
      }

      <div className={styles.publicationCardContent}>
        <Avatar
          onClick={() => navigate(`/profile/user/${item?.playerTarget?.username}`)}
          className={styles.cursorPointer}
          alt='username'
          src={item?.playerTarget?.profileImageUrl}
        />
        <div className={styles.widthFull}>

          <div className={styles.publicationCardPlayerDetailsAndMoreHorizIcon}>

            <div className={styles.publicationCardPlayerDetails}>
              <span><strong>{item?.playerTarget?.name}</strong></span>
              <RenderPremiumBadge activeSubscriptionType={item?.playerTarget?.activeSubscriptionType}/>
              <span className={styles.textGray500}>{item?.playerTarget?.username} · {displayTime}</span>
            </div>

            <div className={styles.moreHorizIconContainer}>
              <button
                onClick={handleClick}>
                <MoreHorizIcon className={styles.moreHorizIcon} />
              </button>
              <Menu id="basic-menu" anchorEl={anchorEl}
                open={open}
                onClose={handleCloseReplyModal}
                MenuListProps={{
                  "aria-labelledby": "basic-button"
                }}>

                <MenuItem onClick={handleDeletePublication}>Delete</MenuItem>
                <MenuItem onClick={handleDeletePublication}>Edit</MenuItem>

              </Menu>
            </div>

          </div>

          <div className={styles.mt0}>
            <div onClick={() => navigate(`/social/posts/${item?.id}`)} className={styles.cursorPointer}>
              <p className={styles.publicationCardText}>
                {item?.content}
              </p>
            </div>

            {/* TODO: añadir también el iframe para los vídeos de youtube y hacer un div que englobe a todo para que no esté en vertical sino en horizontal dos contenido (que es el límite)*/}
            <div className={styles.mediaContent}>

              {item?.image.length > 0 && (
                <div className={styles.mediaPreview}>
                  <img className={styles.responsiveImage} src={item?.image[0]} alt='' />
                </div>
              )}

              {item?.image.length > 1 && (
                <div className={styles.mediaPreview}>
                  <img className={styles.responsiveImage} src={item?.image[1]} alt='' />
                </div>
              )}


              {item?.video.length > 0 && (
                <div className={styles.mediaPreview}>
                  <div className={styles.videoWrapper}>
                    <iframe
                      width="400"
                      height="225"
                      src={`https://www.youtube.com/embed/${getYouTubeVideoId(item?.video[0])}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                      className={styles.responsiveIframe}
                    ></iframe>
                  </div>
                </div>
              )}

              {item?.video.length > 1 && (
                <div className={styles.mediaPreview}>
                  <div className={styles.videoWrapper}>
                    <iframe
                      width="400"
                      height="225"
                      src={`https://www.youtube.com/embed/${getYouTubeVideoId(item?.video[1])}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                      className={styles.responsiveIframe}
                    ></iframe>
                  </div>

                </div>
              )}

            </div>


          </div>

          <div className={styles.publicationCardButtonsContainer}>

            <div className={styles.inactiveButton}>
              <div className={styles.iconCounterComment} onMouseEnter={() => setMouseOnCommentIcon(true)} onMouseLeave={() => setMouseOnCommentIcon(false)} onClick={handleOpenReplyModal}>
                <div className={styles.iconComment}>
                  <CommentIcon fillColor={mouseOnCommentIcon ? '#099F5D' : '#71767B'} className={styles.inactive15HeightIcon} />
                </div>
              </div>
              <p>{displayNumberOfReplies}</p>
            </div>

            <div className={`${styles.iconCounterRepost} ${item?.reposted ? styles.blue : styles.grayHoverBlue}`} onMouseEnter={() => setMouseOnRepostIcon(true)} onMouseLeave={() => setMouseOnRepostIcon(false)} onClick={handleCreateRePost}>
              <div className={styles.iconRepost}>
                {item?.reposted
                  ? <RepostIcon fillColor={'#0ea5e9'} className={styles.inactive15HeightIcon} />
                  : <RepostIcon fillColor={mouseOnRepostIcon ? '#0ea5e9' : '#71767B'} className={styles.inactive15HeightIcon} />
                }
              </div>
              <p>{displayNumberOfReposts}</p>
            </div>

            <div className={`${styles.iconCounterLike} ${item?.liked ? styles.red : styles.grayHoverRed}`} onMouseEnter={() => setMouseOnLikeIcon(true)} onMouseLeave={() => setMouseOnLikeIcon(false)} onClick={handleLikePost}>
              <div className={styles.iconOutlinedLike}>
                {item?.liked
                  ? <FilledLikeIcon fillColor={'#F91880'} className={styles.inactive15HeightIcon} />
                  : <OutlinedLikeIcon fillColor={mouseOnLikeIcon ? '#F91880' : '#71767B'} className={styles.inactive15HeightIcon} />
                }
              </div>
              <p>{displayNumberOfLikes}</p>
            </div>


            <div className={styles.iconCounterVisualizations} onMouseEnter={() => setMouseOnVisualizationsIcon(true)} onMouseLeave={() => setMouseOnVisualizationsIcon(false)}>
              <div className={styles.iconVisualizations}>
                <VisualizationsIcon fillColor={mouseOnVisualizationsIcon ? '#099F5D' : '#71767B'} className={styles.inactive15HeightIcon} />
              </div>

              <p>{displayNumberOfVisualizations}</p>
            </div>

            <div className={styles.inactiveButton}>
              <div className={styles.iconCounterComment} onMouseEnter={() => setMouseOnShareIcon(true)} onMouseLeave={() => setMouseOnShareIcon(false)}>
                <div className={styles.iconComment}>
                  <ShareIcon fillColor={mouseOnShareIcon ? '#099F5D' : '#71767B'} className={styles.inactive15HeightIcon} />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <section>
        <PostReplyModal open={openReplyModal} handleClose={handleCloseReplyModal} repliedPost={item} displayTime={displayTime} handleOpenLimitPostsModal={openLimitPostsModal} />
      </section>
    </div >
  );

};

export default PublicationCard